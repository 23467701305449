import { types } from "../types/types";

const initialState = {
    gastosTotales: []
};

export const gastosTotalesReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.gastosTotales:
            return{
                ...state,
                gastosTotales: [...action.payload]
            }
        
    
        default:
            return state;
    }

}