import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { uiOpenModal } from "../../actions/ui";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export const BotonFlotante = () => {

  //obtengo nombre
  const {name} = useSelector(state => state.auth)

  //dispatch para disparar acciones
  const dispatch = useDispatch();

  //funcion abrir modal
  const openModal = () => {
    dispatch(uiOpenModal());
  };

  return (
    <button
      onClick={openModal}
      className={`p-0 w-16 h-16 float-right boton-flotante ${name === 'Mirian' ? 'bg-pink-600 hover:bg-pink-700' : 'bg-indigo-600 hover:bg-indigo-700'}  rounded-full   mouse shadow-lg transition ease-in duration-200 focus:outline-none`}
    >

<FontAwesomeIcon className="text-white" icon={faPlus} />
     {/*  <svg
        viewBox="0 0 20 20"
        enable-background="new 0 0 20 20"
        className="w-6 h-6 inline-block"
      >
        <path
          fill="#FFFFFF"
          d="M16,10c0,0.553-0.048,1-0.601,1H11v4.399C11,15.951,10.553,16,10,16c-0.553,0-1-0.049-1-0.601V11H4.601
                                    C4.049,11,4,10.553,4,10c0-0.553,0.049-1,0.601-1H9V4.601C9,4.048,9.447,4,10,4c0.553,0,1,0.048,1,0.601V9h4.399
                                    C15.952,9,16,9.447,16,10z"
        />
      </svg> */}
    </button>
  );
};
