import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
    BrowserRouter as Router,
    Switch,
    Redirect
  } from "react-router-dom";
import { startChecking } from '../actions/auth';
import { LoginScreen } from '../components/auth/LoginScreen';
import { ControlScreen } from '../components/controlGastos/ControlScreen';
import { DeTercerosScreen } from '../components/deTerceros/DeTercerosScreen';
import { GastosScreen } from '../components/gastosTotal/GastosScreen';
import { PonerseAlDiaScreen } from '../components/ponerseAlDia/PonerseAlDiaScreen';
import { Loading } from '../components/ui/Loading';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

export const AppRouter = () => {

  const dispatch = useDispatch();

  const {checking, uid} = useSelector(state => state.auth)
  
  

  useEffect(() => {
    dispatch(startChecking())
  }, [dispatch])

  if (checking) {
    return (
      <Loading />
    )
  }

    return (
        <Router>
        <>
          <Switch>


            <PublicRoute exact path="/login" component={LoginScreen} isAuthenticated={!!uid}/>

            <PrivateRoute exact path="/mis-gastos"  component={ControlScreen} isAuthenticated={!!uid}/>
            <PrivateRoute exact path="/de-terceros"  component={DeTercerosScreen} isAuthenticated={!!uid}/>
            <PrivateRoute exact path="/gastos"  component={GastosScreen} isAuthenticated={!!uid}/>
            <PrivateRoute exact path="/ponerse-al-dia"  component={PonerseAlDiaScreen} isAuthenticated={!!uid}/>

            <Redirect to="/mis-gastos" />
          </Switch>
        </>
      </Router>
    )
}
