import React from 'react'
import dayjs from 'dayjs'

export const GastosTerceros = ({fecha, cuotas, item, costo, costoCuotas, gasto}) => {
    return (
        <div>
            <span className="text-gray-400 relative inline-block date font-medium tracking-widest">{dayjs(fecha).format('DD/MM/YYYY')}</span>
            <div className="flex mb-2">
                <div className="w-1/12">
                    <span className="bg-blue-400 h-2 w-2 rounded-full block mt-2"></span>
                </div>
                <div className="w-9/12">
                {
                        gasto.descuento === 'NO' ? 
                        (
                            <span className="text-sm text-gray-500 font-semibold line-through block capitalize ">{item}</span>
                            
                        )
                        :
                        (
                            <span className="text-sm font-semibold block capitalize ">{item}</span>
                        ) 
                    }
                    <span className="text-sm font-bold text-green-500">$ {costo} </span>
                    {cuotas > 1 && (
                        <span className="text-sm text-indigo-700">({cuotas} x {(costoCuotas).toFixed(2)})</span>
                    )}                    
                </div>
            </div>
        </div>
    )
}
