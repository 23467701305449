import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCalculator,faMale, faSignOutAlt, faMoneyBillWave, faHistory } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { startLogout } from '../../actions/auth';
import { useDispatch, useSelector } from 'react-redux';

export const NavBar = () => {

  //obtengo nombre
const {name} = useSelector(state => state.auth)

  const dispatch = useDispatch()

    //estado menu desplegable
  const [navbarOpen, setNavbarOpen] = React.useState(false);

  const handleLogout = () => {
    dispatch(startLogout());
  }

  
  return (
    <>
      <nav className={`relative flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg ${name === 'Mirian' ? 'bg-pink-500' : 'bg-indigo-600'}`}>
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              className="text-xl font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase text-white"
              to="/"
            >
              Control de Gastos <span className="font-semibold text-xs">{name}</span>
            </Link>

            
            <button
              className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <FontAwesomeIcon icon={faBars} />
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="nav-item">
                <Link
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  to="/mis-gastos"
                  onClick={() => setNavbarOpen(!navbarOpen)} //Minimiza menu luego del click
                >
                  <FontAwesomeIcon icon={faMoneyBillWave} />
                  <span className="ml-2">Mis Gastos</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  to="/de-terceros"
                  onClick={() => setNavbarOpen(!navbarOpen)} //Minimiza menu luego del click
                >
                  <FontAwesomeIcon icon={faMale} />
                  <span className="ml-2">Gastos de {name !== 'Norberto' ? 'Norberto' : 'Mirian'}</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  to="/ponerse-al-dia"
                  onClick={() => setNavbarOpen(!navbarOpen)} //Minimiza menu luego del click
                >
                  <FontAwesomeIcon icon={faCalculator} />
                  <span className="ml-2">Ponerse al Dia</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  to="/gastos"
                  onClick={() => setNavbarOpen(!navbarOpen)} //Minimiza menu luego del click
                >
                  <FontAwesomeIcon icon={faHistory} />
                  <span className="ml-2">Total Gastos</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75 border rounded"
                  to="/logout"
                  //onClick={logout} //Minimiza menu luego del click
                >
                  <FontAwesomeIcon icon={faSignOutAlt} />
                  <span onClick={handleLogout} className="ml-2">Logout</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
    )
}
