import { fetchContoken, fetchSintoken } from "../helpers/fetch"
import { types } from "../types/types";
import Swal from 'sweetalert2'
import { gastoLogout } from "./gastos";


export const startLogin = (email, password) => {
    return async (dispatch) => {
        
        const resp = await fetchSintoken('users/login', {email, password}, 'POST');
        const body = await resp.json()

        //console.log(body);

        if(body.ok){
            localStorage.setItem('token', body.token);
            localStorage.setItem('token-init-date', new Date().getTime() );

            dispatch(login({
                uid: body.uid,
                name: body.name
            }))
        } else {
            Swal.fire('Error', body.msg, 'error')
        }
    }
}


export const startChecking = () => {
    return async (dispatch) => {

        const resp = await fetchContoken('users/renew');
        const body = await resp.json()

        //console.log(body);

        if(body.ok){
            localStorage.setItem('token', body.token);
            localStorage.setItem('token-init-date', new Date().getTime() );

            dispatch(login({
                uid: body.uid,
                name: body.name
            }))
        } else {
            //Swal.fire('Error', body.msg, 'error')
            dispatch(checkingFinish())
        }
    }
}

const checkingFinish = () => ({
    type: types.authCheckingFinish
})

const login = (user) => ({
    type: types.authLogin,
    payload: user
})

export const startLogout = () => {
    return (dispatch) => {
        localStorage.clear();
        dispatch(gastoLogout())
        dispatch(logout());
   }
}

const logout = () => ({
    type: types.authLogout
})