import React, { useEffect } from "react";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCashRegister } from "@fortawesome/free-solid-svg-icons";

import { NavBar } from "../ui/NavBar";
import { MisGastos } from "../tablas/MisGastos";
import { Link } from "react-router-dom";
import { ModalGastos } from "../ModalGastos/ModalGastos";
import { BotonFlotante } from "./BotonFlotante";
import { useDispatch, useSelector } from "react-redux";
import { gastoStartLoading, gastoTerceroStartLoading } from "../../actions/gastos";

//datos de prueba
/* const misGastos = [
  {
    fecha: "2021-04-10T20:17:22.215Z",
    cuotas: 1,
    active: true,
    _id: "607207f678c6364a1c9009ac",
    comprador: "607127b272b658461868dd7b",
    item: "carniceria",
    costo: 10,
  }
]; */

export const ControlScreen = () => {

  const dispatch = useDispatch()

  useEffect(() => {
    //leer los datos gastos de todo y laburar
    //leer nombre de tercero
    //enviar datos para saldar deuda!, cuando envio datos, recibo un update, no deberia volver hacer nada!

    //leo mis gastos
    dispatch(gastoStartLoading())

    //leo gastos de otros
    dispatch(gastoTerceroStartLoading())

    
  }, [dispatch])

//datos de gastos de terceros
const {gastosterceros} = useSelector(state => state.terceros)
    //console.log('gastosterceros', gastosterceros);
  
//const dispatch = useDispatch()
const {gastos} = useSelector(state => state.gastos)

if(gastos.length < 1){
            localStorage.removeItem('diferencia')
            localStorage.removeItem('totalTercero')            
            localStorage.removeItem('miTotal')
            localStorage.removeItem('deuda')
}

//obtengo nombre
const {name} = useSelector(state => state.auth)
//console.log(name);


//Total Mis Gastos
//calculo gasto total si falla puede ser poque se vuelve a renderizar el componete, probar subir arriba del export
let miGastosFinales = [];
gastos.forEach((gasto) => {
  if(gasto.descuento !== 'NO'){
    miGastosFinales.push(gasto.costo)
  }
});
//console.log('gastos', gastos);

const miTotal = miGastosFinales.reduce((a, b) => a + b, 0);
//console.log('total', total);


//Total Terceros
    //calculo gasto total
    let gastosTercerosAcum = []
    gastosterceros.forEach((gasto) => {
      if(gasto.descuento !== 'NO'){
        gastosTercerosAcum.push(gasto.costo)
      }
    });
    //console.log('gastos', gastos);
    
    const totalTerce = gastosTercerosAcum.reduce((a, b) => a + b, 0)
    //console.log('total', total);

    //console.log('totalTerce', totalTerce, 'miTotal', miTotal);



 //guardar en local Storage
 
 if(miTotal < totalTerce ){
  localStorage.setItem('miTotal', miTotal)
  localStorage.setItem('totalTercero', totalTerce)
  localStorage.setItem('diferencia', (totalTerce-miTotal).toFixed(2))
  localStorage.setItem('deuda', 'si')
 }


    

  return (
    <>
      <NavBar />
      {/* {
                misGastos.map(gasto => <h3>{gasto.item}</h3>)
            } */}
      <div className="flex justify-center p-2 bg-gray-100">
        <div className="bg-white rounded-lg w-3/3 p-2 shadow">
          <h1 className="text-center text-x text-indigo-700 font-bold">
            Mi Gasto al {dayjs(Date.now()).format("DD/MM/YYYY")} es{" "}
            <span className="text-red-500">${(miTotal).toFixed(2)}</span>
          </h1>
        </div>
      </div>

      <div className="flex justify-center p-4 bg-gray-100">
        <div className="bg-white rounded-lg w-full p-4 shadow overflow-x-auto h-96">
          {gastos.map((gasto) => (
            <MisGastos
              key={gasto._id}
              fecha={gasto.fecha}
              cuotas={gasto.cuotas}
              item={gasto.item}
              costo={gasto.costo}
              costoCuotas={gasto.costoCuotas}
              gasto={gasto}
            />
          ))}
        </div>
      </div>

      {
        miTotal < totalTerce ? (

          <div className="flex justify-center p-2 bg-gray-100">
        <div className="bg-white rounded-lg w-full p-2 shadow">
          <h3 className="text-center text-xl text-red-700 font-bold">
            Tu Saldo es Negativo
          </h3>
          <h3 className="text-center text-xs text-red-700 font-bold">
            Le debes a {name !== 'Norberto' ? 'Norberto' : 'Mirian'}: ${((totalTerce - miTotal)/2).toFixed(2)}
          </h3>
          <Link
            to="/ponerse-al-dia"
            className="mt-2 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-2 "
          >
            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              <FontAwesomeIcon icon={faCashRegister} />{" "}
            </span>
            Ponerse Al Dia
          </Link>
        </div>
      </div>

        ) : (

          <div className="flex justify-center p-2 bg-gray-100">
        <div className="bg-white rounded-lg w-full p-2 shadow">
          <h3 className="text-center text-xl text-green-700 font-bold">
            Tu Saldo es Positivo
          </h3>
          <h3 className="text-center text-xs text-red-700 font-bold">
            {name !== 'Norberto' ? 'Norberto' : 'Mirian'} te debe: ${((miTotal - totalTerce)/2).toFixed(2)}
          </h3>
        </div>
      </div>

        )
        
      }

      <BotonFlotante />

      <ModalGastos />
    </>
  );
};
