import Swal from "sweetalert2";
import { fetchContoken } from "../helpers/fetch";
import { types } from "../types/types";


export const gastoStartAddNew = (gasto) => {
    return async (dispatch) => {
        
        try {
            const resp = await fetchContoken('gastos/crear-gasto', gasto, 'POST');
            const body = await resp.json()

            //console.log('body', body.Gasto._id);

        if (body.ok){
            //gasto.id = body.Gasto._id
            //console.log('gasto sin crear', gasto);
            //console.log('gasto sin crear', body.gasto);
            dispatch(gastoAddNew(body.Gasto));
        }

        } catch (error) {
            console.log(error);
        }
    }
}

const gastoAddNew = (gasto) => ({
        type: types.gastoAddNew,
        payload: gasto
});

export const gastoSetActive = (gasto) => ({
    type: types.gastoSetActive,
    payload: gasto
});

export const limpiarGastoActivo = () => ({
    type: types.gastoActivoLimpiar
})


export const gastoStartUpdate = (gasto) => {
    return async (dispatch) => {
        try {
            
            //console.log(gasto);
            const resp = await fetchContoken(`gastos/update-gasto/${gasto._id}`, gasto, 'PUT' )
            const body = await resp.json()

            //console.log('body', body);

            if(body.ok){
                dispatch(updateGasto(body.dataUpdate))
            }else{
                Swal.fire('Error', body.msg, 'error')
            }


        } catch (error) {
            console.log(error);
        }
    }
}


const updateGasto = (gasto) => ({
    type: types.gastoUpdate,
    payload: gasto
})

export const gastoStartDelete = () => {
    return async (dispatch, getState) => {

        const {_id} = getState().gastos.gastoActivo;
        //console.log(_id);

        //return console.log('test');
        try {
            
            //console.log(gasto);
            const resp = await fetchContoken(`gastos/eliminar-gasto/${_id}`, {}, 'DELETE' )
            const body = await resp.json()

            //console.log('body', body);

            if(body.ok){
                dispatch(eliminarGastoFun())
            }else{
                Swal.fire('Error', body.msg, 'error')
            }


        } catch (error) {
            console.log(error);
        }
    }
}

const eliminarGastoFun = () => ({
    type: types.gastoEliminar
})

//leo los eventos mios y de terceros?
export const gastoStartLoading = () => {
    return async (dispatch) => {

        try {
            
            const resp = await fetchContoken('gastos/mis-gastos')
            const body = await resp.json();
            //console.log(body.gastos);

            const gastos = body.gastos

            dispatch(gastoLoaded(gastos))

        } catch (error) {
            console.log(error);
        }
        
    }
}

const gastoLoaded = (gastos) => ({
    type: types.gastosLoaded,
    payload: gastos
})

//leo gastos de terceros

export const  gastoTerceroStartLoading = () => {
    return async (dispatch) => {
        try {

            const resp = await fetchContoken('gastos/gastos-de-otros')
            const body = await resp.json();
            //console.log(body.gastos);

            const gastos = body.gastos

            dispatch(gastoterceroLoaded(gastos))
            //gastos/gastos-de-otros
            
        } catch (error) {
            console.log(error);
        }
    }
}

const gastoterceroLoaded = (gastos) => ({
    type: types.gastoTerceroLoades,
    payload: gastos
})

//leo gastos totales

export const  gastosTotalesLoading = () => {
    return async (dispatch) => {
        try {

            const resp = await fetchContoken('gastos/gastos')
            const body = await resp.json();
            //console.log(body.gastos);

            const gastos = body.gastos

            dispatch(gastosTotales(gastos))
            //gastos/gastos-de-otros
            
        } catch (error) {
            console.log(error);
        }
    }
}

const gastosTotales = (gastos) => ({
    type: types.gastosTotales,
    payload: gastos
})

//fin leo gastos totales

export const gastoLogout = () => ({
    type: types.gastoLogout
})

/*
export const gastosSaltar = (gastos) => {
    return async (dispatch) => {
        try {
            
            
            //console.log(gasto);
            const resp = await fetchContoken(`astos/saldar-gasto`, gastos, 'PUT' )
            const body = await resp.json()

            console.log(body);

            

            //console.log('body', body);

            /* if(body.ok){
                dispatch(updateGasto(body.dataUpdate))
            }else{
                Swal.fire('Error', body.msg, 'error')
            } */


       /* } catch (error) {
            console.log(error);
        }
    }
}*/