import React, { useEffect } from 'react'
import { NavBar } from '../ui/NavBar'
import dayjs from "dayjs";
import { useDispatch, useSelector } from 'react-redux';
import { gastosTotalesLoading } from '../../actions/gastos';
import { GastosTotales } from '../tablas/GastosTotales';

export const GastosScreen = () => {

   const dispatch = useDispatch()


   useEffect(() => {

    //leo gastos de otros
    dispatch(gastosTotalesLoading())

    
  }, [dispatch])

  const {gastosTotales} = useSelector(state => state.gastosTotales)
  //console.log('gastosTotales', gastosTotales);

   //calculo gasto total
let gastos = []
gastosTotales.forEach((gasto) => {
    gastos.push(gasto.costo)
  });
//console.log('gastos', gastos);

const total = gastos.reduce((a, b) => a + b, 0)
//console.log('total', total);

    return (
<div>
        <NavBar />
        {/* {
            misGastos.map(gasto => <h3>{gasto.item}</h3>)
        } */}
        <div className="flex justify-center p-2 bg-gray-100 ">
        <div className="bg-green-50 rounded-lg w-3/3 p-2 shadow">
        <h1 className="text-center text-x text-black font-bold">El Total de Gastos al {dayjs(Date.now()).format('DD/MM/YYYY')} es <span className="text-red-500">${(total).toFixed(2)}</span></h1>
        </div>
        </div>
        
        <div className="flex justify-center p-4 bg-gray-100 ">                
            <div className="bg-green-50 rounded-lg w-full p-4 shadow overflow-x-auto h-full">
             {
                gastosTotales.map(gasto => 
                    <GastosTotales key={gasto._id} fecha={gasto.fecha} cuotas={gasto.cuotas} item={gasto.item} costo={gasto.costo} costoCuotas={gasto.costoCuotas}/>
                )
            }


            </div>
        </div>

    </div>
    )
}
