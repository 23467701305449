import { combineReducers } from "redux";

import { authReducer } from "./authReducer";
import { gastosReducer } from "./gastosReducer";
import { tercerosReducer } from "./tercerosReducer";
import { uiReducer } from "./uiReducer";
import { gastosTotalesReducer} from "./gastosTotalesReducer"

//todos mis reducers

export const rootReducer = combineReducers({
    ui: uiReducer,
    gastos: gastosReducer,
    terceros: tercerosReducer,
    gastosTotales: gastosTotalesReducer,
    auth: authReducer
    //gastos reducer
})