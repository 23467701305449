import React, { useEffect } from 'react'
import dayjs from 'dayjs';
import { GastosTerceros } from '../tablas/GastosTerceros'
import { NavBar } from '../ui/NavBar'
import { useDispatch, useSelector } from 'react-redux';
import { gastoTerceroStartLoading } from '../../actions/gastos';



export const DeTercerosScreen = () => {

    const dispatch = useDispatch()
    
    useEffect(() => {
        //leer los datos gastos de todo y laburar
        //leer nombre de tercero
        //enviar datos para saldar deuda!, cuando envio datos, recibo un update, no deberia volver hacer nada!
    
        //leo mis gastos
        //dispatch(gastoStartLoading())
    
        //leo gastos de otros
        dispatch(gastoTerceroStartLoading())
    
        
      }, [dispatch])

    const {gastosterceros} = useSelector(state => state.terceros)
    //console.log('gastosterceros', gastosterceros);

    //obtengo nombre
const {name} = useSelector(state => state.auth)
//console.log(name);

    //calculo gasto total
let gastos = []
gastosterceros.forEach((gasto) => {
    if(gasto.descuento !== 'NO'){
        gastos.push(gasto.costo)
    }
  });
//console.log('gastos', gastos);

const total = gastos.reduce((a, b) => a + b, 0)
//console.log('total', total);

    return (
        <div>
        <NavBar />
        {/* {
            misGastos.map(gasto => <h3>{gasto.item}</h3>)
        } */}
        <div className="flex justify-center p-2 bg-gray-100 ">
        <div className="bg-red-50 rounded-lg w-3/3 p-2 shadow">
        <h1 className="text-center text-x text-black font-bold">El Gasto de {name !== 'Norberto' ? 'Norberto' : 'Mirian'} al {dayjs(Date.now()).format('DD/MM/YYYY')} es <span className="text-red-500">${(total).toFixed(2)}</span></h1>
        </div>
        </div>
        
        <div className="flex justify-center p-4 bg-gray-100 ">                
            <div className="bg-red-50 rounded-lg w-full p-4 shadow overflow-x-auto h-full">
            {
                gastosterceros.map(gasto => 
                    <GastosTerceros key={gasto._id} fecha={gasto.fecha} cuotas={gasto.cuotas} item={gasto.item} costo={gasto.costo} costoCuotas={gasto.costoCuotas} gasto={gasto}/>
                )
            }


            </div>
        </div>

    </div>
    )
}
