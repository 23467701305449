import { types } from "../types/types";

const initialState = {
    gastosterceros: []
};

export const tercerosReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.gastoTerceroLoades:
            return{
                ...state,
                gastosterceros: [...action.payload]
            }
        
    
        default:
            return state;
    }

}