export const types = {
    uiOpenModal: '[ui] Open Modal',
    uiCloseModal: '[ui] Close Modal',

    gastoStartAddNew: '[gastps] Start Add New', 
    gastoSetActive: '[gastos] Set Active ',
    gastoAddNew: '[gastos] Add New ',
    gastoActivoLimpiar: '[gastos] Limpiar Nota Activa',
    gastoUpdate: '[gastos] Actualizar Gasto',
    gastoEliminar: '[gastos] Eliminar Gasto',
    gastosLoaded: '[gasto] Gastos Loaded',
    gastoLogout: '[gasto] Gasto Logout',

    gastoTerceroLoades: '[gastoTercero] Gasto tercero Loades',

    gastosTotales: '[gastosTotales] Cargar gastos Totales',

    //authChecking: '[auth] Cheking Login State',
    authCheckingFinish: '[auth] Finish Cheking login state',
    authStartLogin: '[auth] Start login',
    authLogin: '[auth] Login',
    authStartTokenRenew: '[auth] Start Token Renew',
    authLogout: '[auth] Logout'

}