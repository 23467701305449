import { types } from "../types/types";


/*
{
                fecha: "2021-04-10T20:17:22.215Z",
                cuotas: 6,
                active: true,
                _id: "607207fe78c6364a1c9009ae",
                comprador: "607127b272b658461868dd7b",
                item: "jugueteria",
                costo: 10,
                costoCuotas: 2000,
              }
*/

const initialState = {
        gastos: [],

        gastoActivo: null
};

export const gastosReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.gastoSetActive:
            return{
                ...state,
                gastoActivo: action.payload
            }

        case types.gastoAddNew:
            return{
                ...state,
                gastos: [
                    ...state.gastos,
                    action.payload,
                ]
            }

        case types.gastoActivoLimpiar:
            return{
                ...state,
                gastoActivo: null
            }

        case types.gastoUpdate:
            return{
                ...state,
                gastos: state.gastos.map(
                    gasto => (gasto._id === action.payload._id) ? action.payload : gasto
                )
            }
    
            case types.gastoEliminar:
                return{
                    ...state,
                    gastos: state.gastos.filter(
                        gasto => (gasto._id !== state.gastoActivo._id)
                    ),
                    gastoActivo: null
                }

            case types.gastosLoaded:
                return{
                    ...state,
                    gastos: [...action.payload]
                }

            case types.gastoLogout:
                return{
                    ...initialState
                }

        default:
            return state;
    }

}