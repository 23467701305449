import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { uiCloseModal } from "../../actions/ui";
import { gastoStartAddNew, gastoStartUpdate, limpiarGastoActivo } from "../../actions/gastos";

const initGasto = {
  item: "",
  select: "",
  costo: "",
  cuotas: "1",
  descuento: "SI"
}

export const ModalGastos = () => {
  /* const selectRef = useRef()
  const valueSelect = selectRef.current.value
  console.log(valueSelect); */

  //escuchar store
  const { modalOpen } = useSelector((state) => state.ui);
  const { gastoActivo } = useSelector((state) => state.gastos);

  //dispatch para disparar accion
  const dispatch = useDispatch();

  //estado inicial del formulario
  const [formValues, setFormValues] = useState(initGasto);

  const { item, costo, cuotas, select, descuento } = formValues;

useEffect(() => {
  if(gastoActivo){
    setFormValues(gastoActivo)
  }
  //console.log('gastoActivo',gastoActivo);
}, [gastoActivo, setFormValues])


  //funcion para leer formulario
  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  

  //funcion crear gasto
  const handleSubmitForm = (e) => {
    e.preventDefault();
    //console.log(formValues);

    if(gastoActivo){
      //actualizo evento
        dispatch(gastoStartUpdate(formValues))
        
        
    } else{
      //creo evento
      dispatch(
        gastoStartAddNew(formValues)
      );
      
    }

    
    setFormValues(initGasto);
    closeModal(); //cierro modal luego de enviar gastos
  };

  //funciono cerrar modal
  const closeModal = () => {
    dispatch(uiCloseModal());
    dispatch(limpiarGastoActivo())
    setFormValues(initGasto);
  };

  return (
    <>
      {modalOpen === true && (
        <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster modal">
          <div className="border border-teal-500 modal-container bg-white w-11/12 md:w-11/12 mx-auto rounded shadow-lg z-50 overflow-y-auto">
            <div className="modal-content py-6 text-left px-6 ">
              {/* <!--Title--> */}
              <div className="flex justify-between items-center pb-3">
                <p className="text-xl font-semibold">Cargar Gasto</p>
                <div className="modal-close cursor-pointer z-50">
                  <FontAwesomeIcon
                    onClick={closeModal}
                    icon={faTimesCircle}
                    className="fill-current text-3xl text-red-600"
                  />
                </div>
              </div>
              {/* <!--Body--> */}
              {/* <!-- tabla --> */}
              <form
                onSubmit={handleSubmitForm}
                className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 mt-2"
              >
                {select === "Otro..." ? (
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Item
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      //ref={inputRef}
                      name="item"
                      placeholder="Item de Gasto..."
                      autoFocus
                      value={item}
                      onChange={handleInputChange}
                    />
                  </div>
                ) : (
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Item
                    </label>

                    <select
                      className="form-select mt-1 block w-full shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      name="select"
                      value={select}
                      onChange={handleInputChange}
                    >
                      <option >Elegir opcion</option>
                      <option>Almuerzo/Cena</option>
                      <option>Desayuno/Merienda</option>
                      <option>Super/Almacen/Kiosko</option>
                      <option>Verduleria</option>
                      <option>Farmacia</option>
                      <option>Carniceria</option>
                      <option>Seguro Auto</option>
                      <option>Ferreteria</option>
                      <option>Remis/Uber/Cabify</option>
                      <option>Nafta</option>
                      <option className="font-bold">Otro...</option>
                    </select>
                  </div>
                )}
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Costo
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="number"
                    placeholder="costo..."
                    name="costo"
                    value={costo}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Cuotas
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="number"
                    placeholder="cuotas..."
                    name="cuotas"
                    value={cuotas}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Se Descuenta?
                    </label>

                    <select
                      className="form-select mt-1 block w-full shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      name="descuento"
                      value={descuento}
                      onChange={handleInputChange}
                    >
                      <option >SI</option>
                      <option>NO</option>
                    </select>
                  </div>

                <div className="flexitems-center justify-between">
                  <button
                    className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    {gastoActivo ? 'Actualizar Gasto' : 'Cargar Gasto'}
                  </button>
                </div>
              </form>
              {/* <!-- fin tabla -->
               */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
