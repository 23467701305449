const baseUrl = process.env.REACT_APP_API_URL

console.log(baseUrl)


const fetchSintoken = (endpoint, data, method = 'GET') => {

    const url = `${ baseUrl }/${ endpoint }`;

    if(method === 'GET'){
        return fetch(url)
    }else{
        return fetch(url, {
            method,
            headers:{
                'Content-type': 'application/json'
            },
            body: JSON.stringify(data)
        });
    }

}

const fetchContoken = (endpoint, data, method = 'GET') => {

    const token = localStorage.getItem('token') || ''

    const url = `${ baseUrl }/${ endpoint }`;

    if(method === 'GET'){
        return fetch(url, {
            method,
            headers:{
                'x-token': token
            }
        })
    }else{
        return fetch(url, {
            method,
            headers:{
                'Content-type': 'application/json',
                'x-token': token
            },
            body: JSON.stringify(data)
        });
    }

}


export {
    fetchSintoken,
    fetchContoken
}