import React from 'react'
import dayjs from 'dayjs'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from 'react-redux';
import { gastoSetActive, gastoStartDelete } from '../../actions/gastos';
import { uiOpenModal } from "../../actions/ui";

export const MisGastos = ({fecha, cuotas, item, costo, costoCuotas, gasto}) => {

//console.log(costoCuotas);

//const {gastoActivo} = useSelector(state => state.gastos)
//console.log(gastoActivo);

    const dispatch = useDispatch()
    
     //gasto activo
  const onSelectGasto = (e) => {
    //console.log(e)
    //console.log('Click');
    dispatch(gastoSetActive(e))
    dispatch(uiOpenModal());
  }

  const eliminarGasto = (e) => {
    dispatch(gastoSetActive(gasto))
    dispatch(gastoStartDelete(e))

  }
    

    return (
        <div >
            <span className="text-gray-400 relative inline-block date font-medium tracking-widest">{dayjs(fecha).format('DD/MM/YYYY')}</span>
            <FontAwesomeIcon onClick={()=>eliminarGasto(gasto._id)} className="text-red-400 hover:text-red-500 cursor-pointer float-right" icon={faTrashAlt} />
            <FontAwesomeIcon onClick={()=>onSelectGasto(gasto)} className="text-blue-400 hover:text-blue-500 cursor-pointer float-right mr-3" icon={faEdit} />
            <div className="flex mb-2">
                <div className="w-1/12">
                    <span className="bg-blue-400 h-2 w-2 rounded-full block mt-2"></span>
                </div>
                <div className="w-9/12">
                    {
                        gasto.descuento === 'NO' ? 
                        (
                            <span className="text-sm text-gray-500 font-semibold line-through block capitalize ">{item}</span>
                            
                        )
                        :
                        (
                            <span className="text-sm font-semibold block capitalize ">{item}</span>
                        ) 
                    }
                    
                    
                    <span className="text-sm font-bold text-green-500">$ {costo} </span>
                    {cuotas > 1 && (
                        <span className="text-sm text-indigo-700">({cuotas} x {(costoCuotas).toFixed(2)})</span>
                    )}                    
                </div>
            </div>
        </div>
    )
}
