import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons"

import { NavBar } from '../ui/NavBar'
//import { gastosSaltar } from '../../actions/gastos';
import { useSelector } from 'react-redux';
import { fetchContoken } from '../../helpers/fetch';
import Swal from 'sweetalert2';

export const PonerseAlDiaScreen = () => {

    const [deuda, setDeuda] = useState('no')

    useEffect(() => {
        const estadoDeuda = localStorage.getItem('deuda')
        if(estadoDeuda === 'si'){
            setDeuda('si')
        }  
    }, [])

 


    //const dispatch = useDispatch()

    const {gastosterceros} = useSelector(state => state.terceros)
    const {gastos} = useSelector(state => state.gastos)
    //console.log(gastos, gastosterceros);


    const gastosTotales = gastos.concat(gastosterceros);
    //console.log(gastosTotales);

    const idsGastos = []
    gastosTotales.map(gasto => idsGastos.push(gasto._id))
    //console.log(idsGastos);

       //const totalTercero = localStorage.getItem('totalTercero')
        const diferencia = localStorage.getItem('diferencia')
        //const miTotal = localStorage.getItem('miTotal')


        const saldarDeuda = async (gastos) => {

            //return console.log('???', gastos);
            
            try {
                const resp = await fetchContoken(`gastos/saldar-gasto`, {gastosCombinados: gastos}, 'PUT' )
                //console.log('gastos dentro del put', gastos);
                const body = await resp.json()
                
                 if(body.ok){
                Swal.fire('Perfecto', body.msg,'success')
            }else{
                Swal.fire('Error', body.msg, 'error')
            } 

            } catch (error) {
                console.log(error);
            }

            localStorage.removeItem('diferencia')
            localStorage.removeItem('totalTercero')            
            localStorage.removeItem('miTotal')
            localStorage.removeItem('deuda')
            window.location = '/mis-gastos'
        }
    

    return (
        <>
            <NavBar/>

            {
                deuda === 'si' ? (
                    <div className="flex justify-center p-2 bg-gray-100">
                    <div className="bg-white rounded-lg w-3/3 p-2 shadow">
                    <h1 className="text-center text-x text-indigo-700 font-bold">Saldar Deuda de <span className="text-red-500">$ {(diferencia / 2).toFixed(2)}</span></h1>
                    <h3 className="text-center text-xs text-black font-semibold">Una vez que te podes al dia, presiona el boton!</h3>
                    <button  onClick={() => saldarDeuda(idsGastos)} className="mt-2 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-400 hover:bg-green-500 focus:outline-none focus:ring-2 ">
                     <span className="absolute left-0 inset-y-0 flex items-center pl-3">
         <FontAwesomeIcon icon={faCheckSquare} /> </span>
                  Deuda Saldada!
                </button>
                    </div>
                    </div>
                ) : (
                    <div className="flex justify-center p-2 bg-gray-100">
                    <div className="bg-white rounded-lg w-3/3 p-2 shadow">
                    <h1 className="text-center text-x text-green-500 font-bold">Estas al dia no tenes ninguan Deuda!</h1>
                    </div>
                    </div>
                )
            }

            
            
            


        </>
    )
}
